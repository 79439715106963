import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { CURRENCY, COUNTRY } from '../../config';
export const useFormatBookingIntl = function() {
  const intl = useIntl();
  const { customerMenuSetting } = useSelector(state => state.auth);
  const translate = text => {
    return customerMenuSetting?.menu.filter(elem => elem.name === text)?.[0]?.[intl.locale.toUpperCase()];
  };
  const bookingText = translate('menu.booking.new') || intl.formatMessage({ id: 'menu.booking.old' });

  return (intlId, data) => intl.formatMessage({ id: intlId }, { ...data, booking: bookingText });
};

export const useBookingText = function() {
  const intl = useIntl();
  const { customerMenuSetting } = useSelector(state => state.auth);

  const translate = text => {
    return customerMenuSetting?.menu.filter(elem => elem.name === text)?.[0]?.[intl.locale.toUpperCase()];
  };
  const bookingText = translate('menu.booking.new') || intl.formatMessage({ id: 'menu.booking.old' });

  return bookingText;
};

export const useAppAliasIntlFormatter = function() {
  const intl = useIntl();
  const { customerMenuSetting, shopPointandWalletSetting } = useSelector(state => state.auth);
  const currency = useSelector(state => state.auth.shop?.currency);

  const translate = text => {
    return customerMenuSetting?.menu.filter(elem => elem.name === text)?.[0]?.[intl.locale.toUpperCase()];
  };
  const bookingText = translate('menu.booking.new') || intl.formatMessage({ id: 'menu.booking.old' });
  const booking2Text = translate('menu.booking2') || intl.formatMessage({ id: 'menu.reservation' });
  const depositText = translate('menu.deposit') || intl.formatMessage({ id: 'menu.deposit' });
  const packageText = translate('menu.user.packages') || intl.formatMessage({ id: 'menu.user.packages' });
  const promotionText = translate('menu.reward') || intl.formatMessage({ id: 'promotion' });
  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });
  const walletText =
    shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.credit' });
  const couponText = translate('menu.user.coupons') || intl.formatMessage({ id: 'menu.user.coupons' });
  const currencyText = currency ? intl.formatMessage({ id: currency }) : intl.formatMessage({ id: CURRENCY });
  const countryText = currency ? intl.formatMessage({ id: currency.slice(0, 2) }) : intl.formatMessage({ id: COUNTRY });
  const contactText = translate('menu.contact.us') || intl.formatMessage({ id: 'menu.contact.us' });
  const orderText = translate('menu.order.history') || intl.formatMessage({ id: 'menu.order.history' });

  return ({ id: intlId }, data) =>
    intl.formatMessage(
      { id: intlId },
      {
        booking: bookingText,
        booking2: booking2Text,
        point: pointText,
        wallet: walletText,
        deposit: depositText,
        package: packageText,
        promotion: promotionText,
        coupon: couponText,
        currency: currencyText,
        country: countryText,
        contact: contactText,
        order: orderText,
        ...data
      }
    );
};

export const useTranslate = function() {
  const intl = useIntl();
  return (textId, data = {}) => intl.formatMessage({ id: textId }, { ...data });
};
