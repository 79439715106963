/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../../_helpers';
import { useLang, setLanguage } from '../../../../i18n';
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns';
import { BACKEND_URL } from '../../../../../config';
import { useCustomTheme } from '../../../../../app/hooks/useCustomTheme';

const languages = [
  {
    lang: 'en',
    label: 'EN',
    name: 'English',
    flag: toAbsoluteUrl('/media/svg/flags/260-united-kingdom.svg')
  },
  {
    lang: 'th',
    label: 'TH',
    name: 'Thai',
    flag: toAbsoluteUrl('/media/svg/flags/238-thailand.svg')
  },
  {
    lang: 'zh-TW',
    label: 'ZH-TW',
    name: 'Mandarin-traditional',
    flag: toAbsoluteUrl('/media/svg/flags/202-taiwan.svg')
  },
  {
    lang: 'zh-CN',
    label: 'ZH-CN',
    name: 'Mandarin-simplified',
    flag: toAbsoluteUrl('/media/svg/flags/015-china.svg')
  }
  // {
  //   lang: 'ms',
  //   label: 'MY',
  //   name: 'Malay',
  //   flag: toAbsoluteUrl('/media/svg/flags/118-malasya.svg')
  // }
];

export function LanguageSelectorDropdown() {
  const lang = useLang();

  const user = useSelector(state => state.auth.user);

  const currentLanguage = languages.find(x => x.lang === lang) || languages[0];

  async function changeLanguage(newLanguage) {
    if (user) {
      await saveUserLanguage(newLanguage);
    }
    setLanguage(newLanguage);
  }

  async function saveUserLanguage(newLang) {
    try {
      await axios.put(`${BACKEND_URL}/shopUser/setting/`, {
        lang: newLang,
        shopUser: user.shopUserId
      });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong when trying to set language'
      });
    }
  }
  const customTheme = useCustomTheme();
  const style = customTheme?.navbar;

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <div className="btn btn-icon btn-clean btn-dropdown btn-lg ml-3">
          <img className="h-25px w-25px rounded" src={currentLanguage.flag} alt={currentLanguage.name} />
          <div className="ml-1" style={{ color: style.color }}>
            {currentLanguage.label}
          </div>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround"
        style={{ width: '100px' }}
      >
        <ul className="navi navi-hover py-4">
          {languages.map(language => (
            <li
              key={language.lang}
              className={clsx('navi-item', {
                active: language.lang === currentLanguage.lang
              })}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  changeLanguage(language.lang);
                }}
                className="navi-link"
              >
                <span className="symbol symbol-20 mr-3">
                  <img src={language.flag} alt={language.name} />
                </span>
                <span className="navi-text">{language.name}</span>
              </a>
            </li>
          ))}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
